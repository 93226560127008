.bb--alt-shimmer .al--block-img,
.alb--alt-shimmer .al--block-img {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.bb--alt-shimmer .al__address,
.alb--alt-shimmer .al__address {
  width: 100%;
  max-width: 230px;
  height: 24px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 8px;
}
.bb--alt-shimmer .al__address:hover,
.alb--alt-shimmer .al__address:hover {
  cursor: initial;
}
.al--listing-box-sh .al--listing .al__icon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.al--listing-box-sh .al--listing .al__text {
  width: 120px;
  height: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-right: 0;
}
.alb--alt-shimmer .buildingBtn__shimmer {
  margin: 5px 0;
}
.badge--off {
  display: inline-flex;
  align-items: center;
}
.buildingBtn__shimmer {
  width: 90px;
  min-width: 90px;
  height: 24px;
  background-color: #d2d2d2;
  border-radius: 12px;
  display: inline-flex;
}
.alb--alt-shimmer .buildings__next {
  color: #f5f5f5;
}
.bb--alt-shimmer,
.alb--alt-shimmer,
.upcomingTour--shimmer {
  cursor: initial;
}
.bb--alt-shimmer:hover,
.alb--alt-shimmer:hover,
.upcomingTour--shimmer:hover {
  border-color: #bcbab8;
}
.upcomingTour--shimmer .upComTour__icon {
  background-color: #ededed;
  border-radius: 4px;
  height: 24px;
  width: 28px;
  margin-bottom: 4px;
}
.upcomingTour--shimmer .upComTour__name {
  background-color: #ededed;
  border-radius: 4px;
  width: 126px;
  height: 18px;
  margin-top: 8px;
}
.upcomingTour--shimmer .upComTour__list li .upComTour__list-icon {
  background-color: #ededed;
  border-radius: 4px;
  width: 18px;
  height: 18px;
}
.upcomingTour--shimmer .upComTour__list li .upComTour__list-text {
  background-color: #ededed;
  border-radius: 4px;
  height: 18px;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(1) .upComTour__list-text {
  width: 80%;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(2) .upComTour__list-text {
  width: 60%;
}
.upcomingTour--shimmer .upComTour__list li:nth-of-type(3) .upComTour__list-text {
  width: 50%;
}
.upcomingTour--shimmer .btn__span {
  background-color: #ededed;
  border-radius: 10px;
  height: 32px;
  width: 100%;
  min-width: 200px;
  display: inline-flex;
}
.tl--shimmer .tourListing__img,
.tl--plus-shimmer .tourListing__img {
  background-color: #f5f5f5;
  border-radius: 10px;
}
.tl--shimmer .tourListing--row .para__md-dark,
.tl--plus-shimmer .tourListing--row .para__md-dark,
.tl--alt-shimmer .tourListing--row .para__md-dark {
  background-color: #ededed;
  border-radius: 4px;
  height: 20px;
  width: 80%;
}
.tl--plus-shimmer .plus__shimmer,
.tl--alt-shimmer .plus__shimmer {
  color: #fff;
  font-size: 12px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background-color: #ededed;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.tl--alt-shimmer .tourListing__img {
  background-color: #ededed;
}
.addBtn__shimmer {
  padding: 5px;
  border-radius: 10px;
  min-width: 94px;
  min-height: 32px;
  display: inline-flex;
  background-color: #ededed;
}
.breadcrumb--shimmer {
  margin-bottom: 10px;
  display: flex;
}
.breadcrumb--shimmer .breadcrumbShimmer__span {
  background-color: #ededed;
  border-radius: 4px;
  height: 16px;
  display: inline-flex;
  margin-right: 8px;
  min-width: 30px;
}
.breadcrumb--shimmer .breadcrumbShimmer__span:nth-of-type(1) {
  width: 40px;
}
.breadcrumb--shimmer .breadcrumbShimmer__span:nth-of-type(2) {
  width: 158px;
}
.dashboard--bar-shimmer {
  min-height: 105px;
}
.dbPreview--box .db--preview,
.dashboard--bar-shimmer .db--preview {
  background-color: #ededed;
  border-radius: 4px;
  height: 20px;
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
}
.dbPreview--box .db--preview-icon {
  background-color: #ededed;
  border-radius: 4px;
  height: 20px;
  width: 100%;
  max-width: 70px;
  margin-top: 10px;
  margin-bottom: 8px;
  display: flex;
}
.dbPreview--box {
  display: flex;
  align-items: center;
}
.db__btns {
  width: 188px;
  height: 48px;
  background-color: #ededed;
  border-radius: 10px;
  display: inline-flex;
}
.tourEditTop--shimmer {
  background-color: #f5f5f5;
  border-radius: 15px;
}
.placement--shimmer-alt {
  background-color: #ebebeb;
  border-radius: 15px;
  min-height: 327px;
}
.dropdown--shimmer {
  border: 1px solid #bcbab8;
  border-radius: 10px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px 14px;
}
.dropdown--shimmer .dropdownShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #ededed;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-flex;
}
.dropdown--shimmer .dropdownShimmer__text {
  background-color: #ededed;
  border-radius: 4px;
  height: 18px;
  width: 100%;
  max-width: 200px;
  display: inline-flex;
  margin-right: auto;
}
.dropdown--shimmer .dropdownShimmer__arrow {
  display: inline-flex;
  color: #222222;
  font-size: 10px;
  line-height: 10px;
  margin-right: 2px;
  margin-left: 8px;
}
.lt__shimmer {
  display: inline-flex;
  min-width: 74px;
  height: 20px;
  background-color: #ededed;
  margin-left: 12px;
  border-radius: 4px;
}
.tourEdit--listing-shimmer .tel--top .telTop__icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  margin-right: 8px;
  display: inline-flex;
  background-color: #ededed;
  border-radius: 4px;
}
.tourEdit--listing-shimmer .tel--top .telTop__text {
  height: 18px;
  min-width: 18px;
  display: inline-flex;
  background-color: #ededed;
  border-radius: 4px;
  width: 100%;
  max-width: 270px;
}
.telBtn__shimmer {
  min-height: 48px;
  min-width: 76px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  padding: 0.375rem 0.65rem;
}
.telBtn__shimmer .telBtn__shimmer-icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  background-color: #ededed;
  border-radius: 4px;
}
.telBtn__shimmer .telBtn__shimmer-text {
  height: 18px;
  min-width: 50px;
  background-color: #ededed;
  border-radius: 4px;
  flex: 1;
}
.teamBlock--shimmer .teamBlock--img {
  background-color: #ededed;
}
.teamBlock--shimmer .teamBlock__user {
  height: 16px;
  width: 110px;
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
  margin-bottom: 6px;
}
.teamBlock--shimmer .teamBlock__email {
  height: 16px;
  width: 150px;
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
}
.search--shimmer-lg {
  display: flex;
  align-items: center;
  min-height: 70px;
  padding: 12px 24px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
.search--shimmer-lg .search__icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-color: #ededed;
  border-radius: 4px;
  margin-right: 16px;
  display: inline-flex;
}
.search--shimmer-lg .search__text {
  width: 63%;
  height: 24px;
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
}
.searchBs--shimmer {
  display: flex;
  border: 1px solid #bcbab8;
  border-radius: 16px;
  padding: 14px 16px;
  margin-bottom: 20px;
  min-height: 94px;
}
.searchBs--shimmer .sb__shimmer-img {
  width: 224px;
  min-width: 224px;
  height: 64px;
  border-radius: 10px;
  display: flex;
  margin-right: 20px;
  background-color: #ededed;
}
.searchBs--shimmer .search--bs-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
}
.searchBs--shimmer .search--bs-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}
.searchBs--shimmer .sb__shimmer-heading {
  height: 20px;
  width: 50%;
  background-color: #ededed;
  border-radius: 4px;
  margin-bottom: 12px;
}
.searchBs--shimmer .bs__next {
  color: #ededed;
  font-size: 16px;
  margin: 0px 8px 0px 16px;
}
.searchBs--shimmer-alt {
  min-height: 55px;
}
.searchBs--shimmer-alt .search--bs-inner {
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}
.searchBs--shimmer-alt .sb__shimmer-heading {
  margin-bottom: 0;
  margin-right: 30px;
  width: 100%;
  max-width: 232px;
}
.searchBs--shimmer-alt .buildingBtn__shimmer {
  margin-left: 8px;
  margin-right: 8px;
}
.photos--box-shimmer {
  width: 160px;
  height: 160px;
  min-width: 160px;
  display: flex;
  border-radius: 16px;
  position: relative;
  margin-bottom: 14px;
  background-color: #ededed;
}
.block--search-row .photos--box-shimmer {
  width: 100%;
  min-width: 10px;
  height: 180px;
}
.video--box-shimmer {
  width: 330px;
  min-width: 330px;
  height: 185px;
  border-radius: 16px;
  display: flex;
  position: relative;
  margin-bottom: 14px;
  background-color: #ededed;
}
.block--search-row .video--box-shimmer {
  width: 100%;
  min-width: 10px;
}
.pdf__btn-shimmer {
  display: inline-flex;
  align-items: center;
  border-radius: 10px;
  padding: 11px 16px;
  width: 100%;
  min-height: 72px;
  margin-bottom: 14px;
  background-color: #f6f6f6;
}
.pdf__btn-shimmer .pdf__btn-icon {
  min-width: 32px;
  height: 38px;
  display: inline-flex;
  margin-right: 18px;
  background-color: #ededed;
  border-radius: 4px;
}
.pdf__btn-shimmer .pdf__btn-text {
  width: 100%;
  height: 18px;
  display: inline-flex;
  background-color: #ededed;
  border-radius: 4px;
  margin-bottom: 8px;
}
.pdf__btn-shimmer .pdf__btn-off .pdf__btn-text:last-child {
  margin-bottom: 0;
}
.pdf__btn-shimmer .pdf__btn-off {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dh--shimmer .dhShimmer__heading {
  background-color: #ededed;
  width: 100%;
  max-width: 200px;
  height: 28px;
  border-radius: 4px;
  display: inline-flex;
}
.dh--shimmer .dhShimmer__para {
  background-color: #ededed;
  width: 100%;
  max-width: 90%;
  height: 20px;
  border-radius: 4px;
  display: inline-flex;
}
.db--shimmer {
  background-color: #f5f5f5;
  min-height: 70px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 12px;
}
.db--shimmer .dbShimmer__text {
  height: 22px;
  background-color: #ededed;
  border-radius: 4px;
  width: 100%;
  max-width: 152px;
}
.db--shimmer .dbShimmer__icon {
  width: 22px;
  height: 22px;
  min-width: 22px;
  background-color: #ededed;
  border-radius: 4px;
  margin-left: 10px;
}
.search--shimmer {
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 10px 16px;
  background-color: #f5f5f5;
  border-radius: 10px;
  width: 100%;
}
.search--shimmer .searchShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
  background-color: #ededed;
  border-radius: 4px;
  margin-right: 12px;
  display: inline-flex;
}
.search--shimmer .searchShimmer__text {
  width: 70%;
  height: 16px;
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
}
.button__shimmer {
  border: none;
  text-align: center;
  padding: 10px 20px;
  min-height: 48px;
  color: #fff;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.button__shimmer .button__shimmer-text {
  width: 100%;
  max-width: 100px;
  height: 16px;
  background-color: #ededed;
  border-radius: 4px;
}
.button__shimmer .button__shimmer-icon {
  width: 14px;
  min-width: 14px;
  height: 14px;
  background-color: #ededed;
  border-radius: 4px;
  margin-left: 8px;
}
.button__shimmer-lg {
  min-width: 200px;
}
.button__shimmer-sm {
  padding: 10px 16px;
  min-width: 188px;
}
.dashbaord--opt .button__shimmer-sm {
  margin-left: 20px;
}
.shimmer__style {
  background-color: #ededed;
  border-radius: 4px;
  display: inline-flex;
}
.header__logo-shimmer {
  width: 151px;
  height: 39px;
}
.header__bars-shimmer {
  width: 24px;
  height: 18px;
}
.viewBy--shimmer {
  min-height: 26px;
}
.viewBy--shimmer .viewBy__text {
  height: 18px;
  min-width: 61px;
}
.viewBy--shimmer .viewBy__marker {
  height: 22px;
  min-width: 15px;
}
.grid--shimmer .grid__view {
  width: 20px;
  height: 22px;
}
.shimmer__heading-al {
  width: 50%;
  height: 28px;
}
.alb--lg-shimmer:hover {
  cursor: initial;
  border-color: #bcbab8;
}
.alb--lg-shimmer .al__address {
  width: 100%;
  max-width: 230px;
  height: 24px;
  margin-bottom: 12px;
}
.sdf__para-shimmer {
  width: 100%;
  max-width: 210px;
  height: 18px;
  margin-bottom: 16px;
}
.fl--shimmer {
  min-height: 24px;
}
.fl--shimmer .fl--shimmer-span {
  height: 18px;
  margin-right: 24px;
}
.fl--shimmer .fl--shimmer-span:last-child {
  margin-right: 0;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(1) {
  min-width: 55px;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(2) {
  min-width: 95px;
}
.fl--shimmer .fl--shimmer-span:nth-of-type(3) {
  min-width: 136px;
}
.fp__box {
  min-height: 88px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.fp__box .fp__box-span {
  height: 18px;
  width: 100%;
  max-width: 152px;
  margin-bottom: 4px;
}
.fp__box .fp__box-span:nth-of-type(2) {
  max-width: 235px;
}
.shimmer__back {
  display: inline-flex;
  align-items: center;
  min-height: 24px;
}
.shimmer__back .back__btn-icon {
  color: #ededed;
}
.shimmer__back .shimmer__back-text {
  height: 20px;
  width: 40px;
}
.shimmer__heading-lg {
  height: 40px;
  width: 100%;
  max-width: 50%;
}
.shimmer__heading-md {
  height: 24px;
  width: 100%;
  max-width: 25%;
}
.shimmer--keybox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #f7f7f7;
  border-radius: 20px;
  margin-bottom: 20px;
  min-height: 118px;
}
.shimmer--keybox .keyBox--head {
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}
.shimmer--keybox .keyBox--head .keyBoxHead__icon {
  height: 16px;
  min-width: 16px;
  margin-right: 10px;
}
.shimmer--keybox .keyBox--head .keyBoxHead__text {
  height: 16px;
  width: 100%;
  max-width: 104px;
}
.shimmer--keybox .shimmer__heading-md {
  height: 18px;
  max-width: 90px;
  margin-bottom: 10px;
}
.shimmer--keybox .small__paragraph {
  height: 16px;
  width: 100%;
  max-width: 90px;
}
.shimmer--keybox-expand .small__paragraph {
  max-width: 275px;
}
.shimmer--video {
  height: 467px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
  background-color: #f7f7f7;
}
.shimmer__play-btn {
  color: #fff;
  font-size: 28px;
  width: 60px;
  height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.shimmer__play-highlight {
  min-width: 232px;
  min-height: 48px;
  border-radius: 10px;
  padding: 8px 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
}
.shimmer__play-highlight .shimmer__play-text {
  width: 100%;
  max-width: 168px;
  height: 20px;
}
.button__shimmer.button__shimmer-expand .button__shimmer-text {
  max-width: 300px;
}
.photos--lg-shimmer {
  width: 200px;
  height: 200px;
  min-width: 200px;
  display: flex;
  border-radius: 16px;
  margin-bottom: 14px;
}
.block--search-row .photos--lg-shimmer {
  width: 100%;
  min-width: 10px;
  height: 180px;
}
.shimmer--dc .para__md-gray {
  height: 20px;
  width: 100%;
  margin-bottom: 8px;
}
.shimmer--dc .dc--para-column .para__md-gray:last-child {
  margin-bottom: 0;
}
.map--block-shimmer .shimmer__heading-lg,
.sdl--combine .shimmer__heading-lg {
  max-width: 100%;
  width: initial;
  min-width: 224px;
}
.spaces--box-shimmer {
  min-height: 21px;
}
.spaces--box-shimmer .para__sm-gray {
  min-height: 16px;
  min-width: 112px;
}
.shimmer--floorplan .floorplan--img {
  height: 350px;
  width: 100%;
}
.map--block-shimmer .map--box {
  border-radius: 20px;
}
.specs--box-shimmer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  min-height: 104px;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
}
.specs--box-shimmer .specsBox__head {
  min-height: 21px;
  width: 100%;
}
.specs--box-shimmer .specsBox__head .specsBox__head-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.specs--box-shimmer .specsBox__head .specsBox__head-text {
  width: 100%;
  max-width: 88px;
  height: 14px;
}
.specs--box-shimmer .para__sm-gray {
  height: 18px;
  width: 100%;
  max-width: 88px;
  margin-top: auto;
  margin-bottom: auto;
}
.specsBox--img-shimmer {
  flex-direction: row;
  justify-content: center;
}
.specs--box-shimmer .specsBox__head-img {
  width: 44px;
  height: 44px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.specs--box-shimmer .para__sm-gray {
  margin-bottom: 6px;
}
.specs--box-shimmer .para--column {
  min-width: 88px;
}
.specs--box-shimmer .para--column .para__sm-gray:last-child {
  margin-bottom: 0;
}
.specsBox--shimmer-alt {
  justify-content: center;
}
.specsBox--shimmer-alt .para__sm-gray {
  max-width: 150px;
}
.subdomain__footer-shimmer {
  background-color: #757879;
  min-height: 310px;
}
.shimmer__md .shimmer__md-text {
  height: 24px;
  width: 100%;
  max-width: 80%;
}
.shimmer__md .shimmer__md-link {
  height: 24px;
  min-width: 85px;
}
.do--btn-canvas {
  display: inline-flex;
}
.do--btn-canvas .db__btns {
  margin-left: 20px;
}
.tab--shimmer {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(188, 186, 184, 0.5);
}
.scroll--hide-x {
  overflow-x: auto;
}
.scroll--hide-x::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.tab--shimmer-fill .tabShimmer--item {
  flex: 1 1 auto;
}
.tab--shimmer .tabShimmer--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 52px;
  position: relative;
}
.tab--shimmer .tabShimmer--link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 0px 20px;
  min-width: 60px;
}
.tab--shimmer .tabShimmer__icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
.tab--shimmer .tabShimmer__text {
  height: 16px;
  width: 100%;
  min-width: 60px;
  margin-left: 16px;
}
.tab--shimmer .tabShimmer__border {
  height: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.subTours--block-shimmer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 152px;
}
.subTours--video-shimmer {
  width: 100%;
  height: 112px;
  border-radius: 10px;
  background-color: #f6f6f6;
  margin-bottom: 16px;
}
.subTours--block-shimmer .para__sm-gray {
  height: 20px;
  width: 100%;
  max-width: 150px;
}
.bb--inner-shimmer {
  min-height: 70px;
}
.bb--inner-shimmer .biShimmer__heading {
  height: 30px;
  width: 100%;
  max-width: 90px;
  margin-bottom: 20px;
}
.bb--inner-shimmer .biShimmer__text {
  height: 20px;
  width: 100%;
  max-width: 120px;
}
.bb--alt-shimmer .buildings__next {
  color: #f5f5f5;
}
.dashbord__shimmer-md {
  height: 20px;
  width: 100%;
  max-width: 150px;
}
.listing__btn-shimmer {
  border: 1px solid #ededed;
  background-color: #fff;
  border-radius: 10px;
  min-height: 144px;
  padding: 22px 16px;
  display: flex;
  flex-direction: column;
}
.listingBtn--bar-shimmer {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}
.listingBtn--bar-shimmer .listingBtn__icon-shimmer {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
.listingBtn--bar-shimmer .listingBtn__heading-shimmer {
  width: 100%;
  max-width: 125px;
  height: 24px;
}
.listingBtn--para-gr {
  display: flex;
  flex-direction: column;
}
.listingBtn--para-gr .listingBtn__para-shimmer {
  height: 18px;
  width: 100%;
  max-width: 90%;
  margin-bottom: 8px;
}
.listingBtn--para-gr .listingBtn__para-shimmer:last-child {
  margin-bottom: 0;
  max-width: 60%;
}
.specsBox--alt-shimmer {
  justify-content: center;
}
.specsBox--alt-shimmer .specsBox__head {
  min-height: 24px;
  margin-bottom: 8px;
}
.specsBox--alt-shimmer .para__sm-gray {
  margin-top: 0;
}
.sbs--mb {
  margin-bottom: 12px;
}
.radioBtn--shimmer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
}
.radioBtn--shimmer .radioBtn__circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
}
.radioBtn--shimmer .radioBtn__text {
  width: 44px;
  height: 16px;
}
.radioBtn--shimmer-alt {
  min-height: 20px;
}
.radioBtn--shimmer-alt .radioBtn__text {
  width: 100%;
  max-width: 90px;
}
.buildings--map.shimmer__style {
  border-radius: 20px;
}
.heading__medium-shimmer {
  height: 28px;
  width: 100%;
  max-width: 252px;
}
.link__btn-shimmer {
  height: 34px;
  width: 200px;
  border-radius: 10px;
}
.render--detail .optLink--shimmer {
  margin-top: 18px;
}
.optLink--shimmer {
  display: flex;
  align-items: center;
}
.optLink--shimmer .link__text {
  width: 56px;
  height: 18px;
}
.optLink--shimmer .link__icon {
  width: 18px;
  height: 18px;
  margin-left: 10px;
}
.heading__sm-shimmer {
  height: 18px;
  width: 100%;
  max-width: 120px;
}
.mcBox--shimmer .mc__icon {
  width: 14px;
  height: 14px;
}
.ps__shimmer {
  height: 14px;
  width: 100%;
  max-width: 70px;
}
.mcc--shimmer {
  margin-bottom: 14px;
}
.media--video-block .media--video-shimmer {
  width: 330px;
  height: 190px;
  flex-shrink: 0;
  border-radius: 16px;
  margin-right: 14px;
}
.pdf__lg-shimmer {
  min-height: 114px;
  flex-direction: column;
}
.floorplanBox--shimmer {
  width: 100%;
  border-radius: 15px;
  padding: 28px;
}
.floorplanBox--shimmer .floorplanBox--img {
  width: 100%;
  max-width: 760px;
  height: 430px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  pointer-events: none;
  border-radius: 15px;
  overflow: hidden;
}
.specsOption--box-shimmer {
  display: inline-flex;
  align-items: center;
}
.specsOption--box-shimmer .specsOpt__icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
  display: flex;
}
.specsOption--box-shimmer .specsOpt__text {
  height: 16px;
  width: 46px;
}
.time--box.specs--box-shimmer {
  justify-content: center;
}
.timeBox__separator .timeBox__separator-text {
  height: 18px;
  width: 20px;
}
.radioBtn--shimmer-inverse .radioBtn__circle {
  margin-right: 0;
  margin-left: 10px;
}
.timeBox--options-shimmer .to--box:first-child .radioBtn--shimmer {
  justify-content: flex-end;
}
.timeBox--options-shimmer .to--box:last-child .radioBtn--shimmer {
  justify-content: flex-start;
}
.location--content-shimmer .locationContent__heading {
  height: 28px;
  width: 100%;
  max-width: 200px;
  flex-shrink: 0;
}
.location--box-shimmer .locationBox__heading {
  height: 20px;
  margin-bottom: 14px;
}
.location--box-shimmer .locationBadge__shimmer {
  min-height: 34px;
  width: 100px;
  border-radius: 20px;
  display: inline-flex;
  flex-shrink: 0;
  margin: 0px 10px 10px 0px;
}
.location--head-shimmer .locationHead__logo {
  width: 151px;
  height: 39px;
  flex-shrink: 0;
  margin-right: 14px;
}
.location--head-shimmer .location__destination {
  width: 129px;
  height: 34px;
}
.editor--shimmer {
  display: flex;
  align-items: center;
  min-height: 42px;
  padding-left: 18px;
  position: relative;
}
.editor--shimmer:before {
  content: '';
  width: 1px;
  background-color: #cccccc;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.editor--shimmer .editorOpt__shimmer {
  width: 24px;
  height: 16px;
  margin-right: 18px;
}
.editorBox--shimmer {
  border-radius: 10px;
  height: 300px;
  width: 100%;
}
.tourEdit--shimmer {
  width: 100%;
  background-color: #f7f7f7;
}
.feature--shimmer {
  width: 100%;
  background-color: #ededed;
}
.feature--shimmer .featureBlock__icon {
  width: 34px;
  height: 34px;
  margin: 0px auto 32px auto;
}
.feature--shimmer .featureBlock__heading.text__gray,
.feature--shimmer .sf__digit {
  width: 100%;
  max-width: 140px;
  height: 24px;
}
.feature--shimmer .featureBlock__heading.text__dark {
  width: 100%;
  max-width: 240px;
  height: 20px;
}
.feature--shimmer .featureShimmer--combine {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}
.tourEdit--shimmer .liveTour--pdf,
.tourEdit--shimmer .liveTour--gallery {
  background-color: #f7f7f7;
}
.tourEdit--shimmer .liveTour--gallery-img {
  border-radius: 10px;
  background-color: #ededed;
  cursor: default;
}
.pdf__shimmer-edit {
  background-color: #ededed;
  min-height: 82px;
}
.pdf__shimmer-edit .pdf__btn-icon {
  width: 30px;
  height: 36px;
}
.specsEdit--shimmer .bw__icon {
  min-width: 14px;
  height: 16px;
}
.tourEdit--specs .specsEdit--shimmer .bw__heading,
.specsEdit--shimmer .bw__heading {
  height: 13px;
  width: 100%;
  max-width: 80px;
  margin-bottom: 8px;
}
.specsEdit--shimmer .bw__para {
  height: 13px;
  width: 100%;
  max-width: 50px;
}
.specsEdit--shimmer .bw__detail {
  display: flex;
  flex-direction: column;
}
.certification--widget.specsEdit--shimmer .bw__icon {
  width: 22px;
  height: 22px;
  flex-shrink: 0;
}
.specsTop--shimmer {
  min-height: 28px;
}
.specsTop--shimmer .dashboard__heading-shimmer {
  height: 26px;
  width: 100%;
  max-width: 270px;
}
.specsList__shimmer {
  position: relative;
  padding-left: 10px;
  margin-left: 10px;
  min-height: 24px;
  display: flex;
  flex: 1;
}
.specsList__shimmer:before {
  content: '';
  width: 1px;
  background-color: #bcbab8;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.specsList__shimmer .specsList__shimmer-text {
  height: 22px;
  width: 100%;
  max-width: 120px;
}
.link__blue-shimmer {
  height: 20px;
  width: 98px;
  flex-shrink: 0;
}
.hms__md {
  max-width: 150px;
}
.createListing--row .tl--alt-shimmer .tourListing--row .para__md-dark {
  width: 70%;
}
.intergrate--shimmer {
  display: inline-flex;
  align-items: center;
}
.intergrate--shimmer .intergrateShimmer__icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.intergrate--shimmer .intergrateShimmer__text {
  min-width: 200px;
  height: 20px;
}
.dashbaord--banner-shimmer {
  width: 100%;
  min-height: 198px;
  border-radius: 16px;
  display: flex;
}
.accountBlock--shimmer {
  width: 100%;
  min-height: 100px;
  border-radius: 16px;
  padding: 24px;
  background-color: #fff;
}
.accountBlock--shimmer .accountBlock--col {
  display: flex;
  flex-direction: column;
}
.accountBlock--shimmer .accountBlockCol__heading {
  width: 150px;
  height: 20px;
}
.accountBlock--shimmer .accountBlockCol__para {
  width: 260px;
  height: 18px;
}
.updateAccount--box-shimmer .updateAccount__icon {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.updateAccount--box-shimmer .updateAccount__detail {
  display: flex;
  flex-direction: column;
}
.updateAccount--box-shimmer .updateAcShimmer__heading {
  width: 70px;
  height: 20px;
  margin-bottom: 6px;
}
.updateAccount--box-shimmer .updateAcShimmer__para {
  width: 70px;
  height: 18px;
}
.spotlight--block-shimmer {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  flex-direction: column;
  background-color: #fff;
}
.spotlight--head-shimmer {
  min-height: 28px;
}
.spotlight--head-shimmer .spotlight--icon {
  width: 24px;
  height: 24px;
}
.spotlight--head-shimmer .spotlight--text {
  width: 160px;
  height: 24px;
}
.shimmerPara--box {
  display: flex;
  flex-direction: column;
}
.shimmerPara--box .shimmer__para {
  width: 80%;
  height: 14px;
}
.card--shimmer {
  width: 100%;
  padding: 24px;
  border-radius: 16px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}
.cardHead--shimmer {
  display: flex;
  align-items: center;
  min-height: 38px;
  flex: 1;
}
.cardHead--shimmer .cdIcon__shimmer {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
.cardHead--shimmer .cdText__shimmer {
  width: 100%;
  max-width: 160px;
  height: 28px;
}
.cardHead--shimmer .cdArrow__shimmer {
  font-size: 18px;
  color: #bcbab8;
  display: inline-flex;
  margin-left: auto;
}
.tourPulse--block-shimmer .tourPulse--link .tourPulseLink__icon,
.tourPulse--block-shimmer .tourPulse--detail .tourPulseDetail__icon,
.tourPulse--block-shimmer .tourPulse--info .tourPulseInfo__icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.tourPulse--block-shimmer .tourPulse--info .tourPulseInfo__heading {
  width: 110px;
  height: 18px;
  margin-bottom: 8px;
}
.tourPulse--block-shimmer .tourPulse--info .tourPulseInfo__date {
  width: 90px;
  height: 16px;
}
.tourPulseLink--shimmer .tourPulseLink__heading {
  height: 18px;
  width: 50%;
}
.tourPulseLink--shimmer .tourLink__shimmer {
  height: 16px;
  width: 90%;
}
.dropdown--shimmer-new {
  padding: 3px 13px;
}
.dropdown--shimmer-new .dropdownShimmer__sub {
  height: 12px;
  width: 100%;
  max-width: 100px;
  margin-bottom: 6px;
}
.dropdown--shimmer-new .dropdownShimmer__text {
  height: 14px;
}
.dropdown--shimmer-new .dropdownShimmer__arrow {
  font-size: 13px;
  display: inline-flex;
}
.listingCheck--shimmer {
  display: flex;
  align-items: center;
}
.listingCheck--shimmer .thBox__circle {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 28px;
}
.th--box-shimmer .thBox__text,
.listingCheck--shimmer .thBox__text {
  height: 18px;
  width: 50px;
}
.th--box-shimmer .thBox__icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}
.dashTable--listing-shimmer .dashTableListing__link {
  height: 14px;
  width: 100%;
  max-width: 190px;
}
.dashboard--table-shimmer tbody td {
  display: table-cell;
}
.dashboard--table-shimmer.dashboard--table-se .dashTable--listing-shimmer .dashTableListing__link {
  max-width: 110px;
}
.tourAnalyticBlock--shimmer .dt__para-shimmer {
  width: 100%;
  max-width: 180px;
  height: 20px;
}
.dashTable--listing-shimmer .dashTableListing__pdf {
  width: 26px;
  height: 32px;
  min-width: 10px;
}
.back__link-shimmer .back__link-text {
  width: 100%;
  max-width: 150px;
  height: 16px;
}
.listingTour--head-shimmer {
  min-height: 28px;
}
.listingTour--head-shimmer .lts__heading {
  width: 100%;
  max-width: 154px;
  height: 24px;
  margin-right: 20px;
}
.listingTour--head-shimmer .shimmer__date {
  width: 100%;
  max-width: 80px;
  height: 20px;
}
.paraCombine--shimmer {
  min-height: 28px;
  width: 100%;
}
.paraCombine--shimmer .paraCombineSh__icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.paraCombine--shimmer .paraCombineSh__text {
  width: 75%;
  height: 20px;
}
.listingToured--shimmer .para__toured {
  width: 100%;
  max-width: 125px;
  height: 20px;
}
.listingTour__btn-shimmer {
  width: 100%;
  height: 26px;
  border-radius: 12px;
  padding: 2px 12px;
  display: flex;
}
.tourAnalyticBlock--shimmer .spotlight--head-shimmer {
  display: flex;
  align-items: center;
  flex: 1;
}
.tourAnalyticBlock--shimmer .spotlight--head-shimmer .spotlight--icon {
  margin-right: 12px;
}
.tourAnalyticBlock--shimmer .spotlight--head-shimmer .spotlight--text {
  width: 100%;
  max-width: 250px;
}
.listingToured--nav-shimmer {
  background-color: #f5f5f5;
  min-height: 36px;
  min-width: 180px;
  border-radius: 30px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}
.listingToured--nav-shimmer .ltn__item {
  min-width: 95px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  min-height: 34px;
}
.listingToured--nav-shimmer .ltn__item-last {
  min-height: 36px;
  margin: -1px;
}
.videoContent--bar-shimmer {
  background-color: #f5f5f5;
}
.videoContent--options-shimmer .videoContent__clock .videoContent--time {
  height: 18px;
  width: 38px;
}
.videoContent--options-shimmer .videoContent__screen .videoContent__screen-text {
  width: 76px;
  height: 20px;
}
.videoContent--options-shimmer .videoContent__speed-btn {
  min-height: 34px;
}
.videoContent--options-shimmer .videoContent__screen .videoContent__screen-size {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.comment--block-shimmer {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
}
.comment--block-shimmer .comment--block-heading {
  width: 100%;
  max-width: 110px;
  height: 20px;
  margin-bottom: 8px;
}
.comment--block-shimmer .shimmerPara--box-xs {
  margin-bottom: 18px;
}
.shimmerPara--box-xs .shimmer__para {
  height: 12px;
}
.commentsGr--shimmer {
  background-color: #fff;
  border: 1px solid #bcbab8;
  border-radius: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
.commentsGr--shimmer .commentsGr__text {
  width: 100%;
  max-width: 44%;
  height: 14px;
}
.commentsGr--shimmer .commentsGr--icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.commentsGr--shimmer .commentsGr--icons .commentsGr__icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-left: 18px;
}
.comment--box-shimmer .commentBox--detail {
  width: 100%;
}
.comment--box-shimmer .cbd--top {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 5px;
  width: 100%;
}
.comment--box-shimmer .cbd--top .cbdt__heading {
  width: 100%;
  max-width: 110px;
  height: 16px;
}
.comment--box-shimmer .cbd--top .cbdt__date {
  width: 100%;
  max-width: 44px;
  height: 16px;
  margin-left: 14px;
}
.comment--box-shimmer .commentBoxOpt__btn {
  width: 18px;
  height: 18px;
}
.paginationShimmer--canvas .dashbord__shimmer-md {
  max-width: 220px;
}
.pagination--shimmer .paginationItem__shimmer {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-left: 10px;
}
.videoTimeline--shimmer {
  display: flex;
  justify-content: space-between;
  min-height: 21px;
  margin-bottom: 22px;
}
.videoTimeline--shimmer .videoTime__shimmer {
  width: 34px;
  height: 18px;
}
.videoTimeline--shimmer .videoProgress__shimmer {
  flex: 1;
  height: 5px;
  margin: 7px 20px 0px 20px;
  position: relative;
}
.videoTimeline--shimmer .videoProgress__shimmer .videoHandler__shimmer {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}
.videoController--shimmer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.videoController--shimmer .vcbIcon__shimmer {
  width: 20px;
  height: 20px;
}
.videoController--shimmer .vcbIcon__shimmer:first-child {
  margin-right: auto;
}
.videoController--shimmer .vcbIcon__shimmer:last-child {
  margin-left: auto;
}
.videoController--shimmer .vcb__shimmer {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-left: 2px;
  margin-right: 2px;
}
.videoController--shimmer .vcb--speed {
  display: flex;
  align-items: center;
}
.vcb--switch {
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.vcb--switch .switchIcon__shimmer {
  width: 26px;
  height: 14px;
}
.vcb--switch .switchText__shimmer {
  width: 80px;
  height: 18px;
  margin: 0 8px;
}
.video--content .videoContent--place.videoContent--place-shimmer {
  height: 408px;
  border-radius: 20px;
}
.sidebar--shimmer {
  border-radius: 0px 20px 20px 0px;
}
.sl-shimmer {
  margin-bottom: 24px;
}
.sl-shimmer .sl__item {
  padding: 12px 0;
  display: flex;
  align-items: center;
  min-height: 48px;
}
.sl-shimmer .sl__item .sl__item-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.sl-shimmer .sl__item .sl__item-text {
  width: 90px;
  height: 20px;
}
.sl-shimmer-alt {
  margin-top: auto;
  margin-bottom: 10px;
}
.sidebar__toggle-shimmer {
  border-radius: 21px;
}
.sidebar--user-shimmer .sidebar__name {
  width: 100%;
  height: 19px;
}
.sidebar--user-shimmer .dropdown__item-span {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}
.sidebar--user-shimmer .sidebar__mention {
  width: 100%;
  height: 16px;
}
.sidebar--user-shimmer .sidebar--detail {
  min-height: 39px;
  display: flex;
  flex-direction: column;
}
.db__btns.btn__lg-width {
  width: 100%;
}
.bs--hp {
  min-height: 70px;
}
.bs--hp .headingMd__shimmer {
  width: 100%;
  max-width: 272px;
  height: 20px;
  margin-top: 6px;
}
.bs--hp .para__md-gray {
  width: 100%;
  max-width: 600px;
  height: 18px;
}
.tempCheck--shimmer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  border: 1px solid #bcbab8;
  border-radius: 10px;
  margin-bottom: 20px;
}
.tempCheck--shimmer .tempCheck--heading {
  width: 100%;
  max-width: 150px;
  height: 18px;
  margin-top: 2px;
}
.tempCheck--shimmer .radioBtn--shimmer {
  min-height: 20px;
}
.tempCheck--shimmer .radioBtn--shimmer .radioBtn__text {
  width: 94px;
}
.tempCheck--shimmer .tempCheck--frame {
  height: 366px;
  border-radius: 10px;
}
.template--banner-shimmer {
  position: static;
  min-height: 800px;
  background-color: rgba(196, 196, 196, 0.1);
}
.tempHeader--shimmer {
  padding-top: 24px;
  padding-bottom: 24px;
}
.add--box-shimmer {
  min-height: 20px;
}
.add--box-shimmer .addBox__icon {
  width: 18px;
  height: 18px;
}
.add--box-shimmer .addBox__text {
  width: 100%;
  max-width: 215px;
  height: 18px;
}
.temp--btn-canvas .db__btns {
  min-width: 220px;
}
.template--banner-shimmer .shimmer__heading-lg {
  height: 38px;
}
.carouselIndicators--shimmer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  margin: 0px 0px 24px 0px;
  padding-left: 20px;
  padding-right: 20px;
}
.carouselIndicators__case {
  width: 32px;
  height: 10px;
  margin: 4px;
}
.temp--about-shimmer .heading__small-white {
  width: 100%;
  max-width: 266px;
  height: 19px;
}
.temp--about-shimmer .heading__lg-white {
  width: 100%;
  max-width: 370px;
  height: 38px;
}
.headingMd--shimmer {
  display: flex;
  flex-direction: column;
}
.headingMd--shimmer .hm__shimmer {
  width: 100%;
  height: 18px;
  margin-bottom: 10px;
}
.temp--about-shimmer .tempAboutColInner--shimmer .heading__lg-white {
  max-width: 166px;
  height: 36px;
}
.temp--about-shimmer .tempAboutColInner--shimmer .heading__tem-white {
  width: 100%;
  max-width: 166px;
  height: 24px;
}
.headingMd--shimmer-cc .hm__shimmer {
  max-width: 180px;
}
.ci--sh {
  margin-bottom: 72px;
  min-height: 168px;
  position: relative;
}
.cis--static {
  position: static;
  padding: 0;
  margin: 0;
}
.tempCombine--head-shimmer {
  display: flex;
  flex-direction: column;
  min-height: 82px;
}
.tempCombine--head-shimmer .tch--text {
  width: 100%;
  max-width: 220px;
  height: 20px;
}
.tempCombine--head-shimmer .tch--heading {
  width: 100%;
  max-width: 160px;
  height: 38px;
  margin-top: 2px;
}
.temp--combine .tempCombine--head-shimmer {
  min-height: 87px;
}
.suite--block-shimmer .suite--detail .heading__th-dark {
  width: 100%;
  max-width: 160px;
  height: 29px;
}
.keyBox--shimmer {
  background-color: rgba(196, 196, 196, 0.1);
  border-color: rgba(196, 196, 196, 0.1);
}
.keyBox--shimmer .add--box-shimmer {
  margin-bottom: 8px;
}
.keyBox--shimmer .add--box-shimmer .addBox__icon {
  width: 16px;
  height: 16px;
}
.keyBox--shimmer .add--box-shimmer .addBox__text {
  max-width: 100px;
  height: 16px;
}
.keyBox--shimmer .heading__medium {
  width: 100%;
  max-width: 200px;
  height: 20px;
  margin-bottom: 8px;
}
.keyBox--shimmer .small__paragraph {
  width: 100%;
  max-width: 100px;
  height: 16px;
}
.temp--combine .tcl--shimmer-canvas {
  padding-top: 21px;
}
.specsBoxTemp--shimmer .specsBox__head {
  margin-bottom: 14px;
}
.specsBoxTemp--shimmer .specsBox__head-icon {
  width: 14px;
  height: 14px;
}
.specsBoxTemp--shimmer .specsBox__head-text {
  width: 100%;
  max-width: 100px;
  height: 16px;
}
.specsBoxTemp--shimmer .para__sm-gray {
  width: 100%;
  max-width: 160px;
  height: 16px;
}
.temp--about-shimmer.temp--specification .heading__lg-white {
  margin-bottom: 27px;
}
.sbs--text {
  display: flex;
  flex-direction: column;
  min-height: 48px;
}
.sbs--text .heading__md-dark {
  width: 100%;
  max-width: 60px;
  height: 16px;
  margin-bottom: 14px;
}
.sbs--text .para__sm-gray {
  width: 100%;
  max-width: 100px;
  height: 14px;
}
.template--head-two .template--banner-shimmer .tempBanner--detail {
  margin-top: 80px;
}
.tch-shimmer-alt .tch--heading {
  max-width: 320px;
}
.temp--map.shimmer__style {
  border-radius: 20px;
}
.tempListing--block-shimmer .heading__tem-dark {
  width: 100%;
  max-width: 120px;
  height: 24px;
}
.info--item-shimmer {
  min-height: 26px;
}
.info--item-shimmer .infoItem__icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
}
.info--item-shimmer .infoItem__text {
  width: 100%;
  max-width: 104px;
  height: 20px;
}
.info--item-shimmer .infoItem--r .infoItem__text {
  width: 18px;
}
.info--item-shimmer .infoItem__para {
  width: 100%;
  max-width: 72px;
  height: 24px;
}
.tempSpecs--block-shimmer {
  display: flex;
  flex-direction: column;
  min-height: 103px;
}
.tempSpecs--block-shimmer .tempSpecs__icon {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  margin-bottom: 20px;
}
.tempSpecs--block-shimmer .heading__md-dark {
  width: 100%;
  max-width: 120px;
  height: 18px;
  margin-bottom: 13px;
}
.tempSpecs--block-shimmer .para__sm-gray {
  width: 100%;
  max-width: 160px;
  height: 16px;
}
.tempListing--block-shimmer .temp--btn-canvas {
  margin-top: 34px;
}
.tempContact--box-shimmer {
  border-radius: 20px;
}
.sustainBox--shimmer-alt .sbs--text {
  align-items: center;
}
.sustainBox--shimmer-alt .sbs--text .heading__md-dark {
  height: 30px;
}
.template--head-three .template--banner-shimmer {
  background-color: transparent;
}
.template--head-three .tempHeadTh--bg-shimmer {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
  margin-left: auto;
  background-color: rgba(196, 196, 196, 0.1);
}
.template--head-three .template--banner-shimmer .shimmer__heading-lg {
  height: 32px;
}
.template--head-three .tempLink--canvas {
  margin-top: 54px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.tempLink--canvas .link--shimmer {
  margin-right: 38px;
  margin-bottom: 10px;
}
.link--shimmer {
  display: inline-flex;
  align-items: center;
  min-height: 24px;
}
.link--shimmer .linkShimmer__text {
  width: 108px;
  height: 20px;
}
.link--shimmer .linkShimmer__arrow {
  width: 22px;
  height: 22px;
  margin-left: 16px;
}
.tempConTh--block--shimmer .tempConTh--detail .heading__tem-dark {
  width: 100%;
  max-width: 180px;
  height: 28px;
}
.tempConTh--block--shimmer .headingMd--shimmer .hm__shimmer {
  max-width: 200px;
  height: 16px;
  margin-bottom: 10px;
}
.tempConTh--block--shimmer .link__blue-shimmer {
  height: 16px;
}
.tempConTh--block--shimmer .tempConTh--img {
  border-radius: 0;
}
.tch-shimmer-custom {
  min-height: 103px;
}
.tch-shimmer-custom .tch--heading {
  max-width: 320px;
}
.tempListingTh--block-shimmer {
  background-color: rgba(196, 196, 196, 0.1);
}
.tempListingTh--block-shimmer .heading__temp {
  width: 100%;
  max-width: 120px;
  height: 24px;
}
.tempListingTh--block-shimmer .heading__tem-white {
  width: 66px;
  height: 24px;
}
.tempListingTh--block-shimmer .para__md-white {
  width: 60px;
  height: 20px;
}
.tempListingTh--block-shimmer .tempListingTh--overlay {
  background-color: transparent;
}
.tempSpecs--th .tch-shimmer-custom {
  min-height: 87px;
}
.tempSpecsBlock--shimmer .tempSpecs__icon {
  width: 32px;
  height: 32px;
}
.tempSpecsBlock--shimmer .tempSpecs--detail {
  display: flex;
  flex-direction: column;
}
.tempSpecsBlock--shimmer .tempSpecs--detail .heading__md-dark {
  width: 100%;
  max-width: 150px;
  height: 18px;
  margin-bottom: 16px;
}
.tempSpecsBlock--shimmer .tempSpecs--detail .para__sm-gray {
  width: 100%;
  max-width: 80px;
  height: 16px;
}
.temp--options .db__btns {
  width: 180px;
  margin-left: 16px;
}
.tempView__btn.shimmer__style {
  border-radius: 10px;
}
.template--header-shimmer .template__name {
  width: 125px;
  height: 22px;
  flex-grow: 0;
}
.db--preview-lg {
  width: 100%;
  max-width: 250px;
  height: 34px;
}
.mobile--nav-shimmer .mobileNav__logo {
  width: 120px;
  height: 32px;
}
.mobile--nav-shimmer .mobileNav__user {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.footer--nav-shimmer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 44px;
}
.footer--nav-shimmer .footerNav--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-height: 40px;
}
.footer--nav-shimmer .footerNav--item .footerNav__icon {
  width: 16px;
  height: 16px;
  margin-bottom: 6px;
}
.footer--nav-shimmer .footerNav--item .footerNav__text {
  width: 100%;
  max-width: 70px;
  height: 15px;
}
.ct--shimmer .ct--head {
  min-height: 40px;
  padding-top: 4px;
}
.ct--shimmer .ct--head .dashboard__heading {
  width: 100%;
  max-width: 240px;
  height: 34px;
}
.ct--shimmer .ct--head .steps__text {
  width: 64px;
  height: 20px;
  margin-left: 20px;
}
.ct--shimmer .ct__para-lg {
  width: 100%;
  max-width: 420px;
  height: 24px;
  margin-top: 3px;
}
.ct--shimmer .ct__para-md {
  width: 100%;
  max-width: 380px;
  height: 22px;
  margin-top: 2px;
}
.ct--radio-shimmer {
  align-items: flex-start;
  cursor: initial;
}
.ct--radio-shimmer .checkmark {
  border-color: #ededed;
  margin-top: auto;
  margin-bottom: auto;
}
.ctRadio--head-shimmer {
  width: 100%;
  min-height: 24px;
}
.ctRadio--head-shimmer .ctRadio__icon {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}
.ctRadio--head-shimmer .ct__para-md {
  height: 18px;
}
.para--sm-box .small__paragraph {
  width: 100%;
  height: 18px;
  margin-bottom: 6px;
}
.formGr--shimmer {
  display: flex;
}
.formGr--shimmer .formGr--cntl {
  width: 100%;
  height: 48px;
  border-radius: 10px;
}
.tourEdit--top-box .tourEdit--logo-shimmer {
  border-radius: 10px;
}
.ct--shimmer .ct__lg-combine {
  min-height: 54px;
}
.ct--shimmer .ct__lg-combine .ct__para-lg {
  max-width: 100%;
  height: 22px;
  margin-top: 4px;
}
.ct--shimmer .ct__lg-combine .ct__para-lg:last-child {
  max-width: 110px;
}
.tour__badge-shimmer {
  width: 100%;
  min-width: 286px;
}
.tour__badge-shimmer .tour__badge-text {
  width: 100%;
  height: 14px;
}
.tour__badge-shimmer .tour__badge-cross {
  width: 18px;
  height: 16px;
}
.createTour--inner-shimmer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 36px;
  padding-top: 4px;
  margin-top: 16px;
  margin-bottom: 22px;
}
.createTour--inner-shimmer .createTour--card-heaing {
  width: 100%;
  max-width: 250px;
  height: 28px;
}
.createTour--inner-shimmer .createTour--card-icon {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  margin-left: 20px;
}
.dashboard--head-shimmer {
  min-height: 48px;
}
.dashboard--head-shimmer .dashboard__heading {
  width: 100%;
  max-width: 140px;
  height: 32px;
}
.db__btns.sm__button {
  min-width: 156px;
  width: auto;
}
.adminSettings--head-shimmer {
  min-height: 36px;
}
.adminSettings--head-shimmer .adminSettings__icon {
  width: 24px;
  height: 24px;
}
.adminSettings--head-shimmer .adminSettings__text {
  width: 100%;
  max-width: 140px;
  height: 24px;
}
.profile--img-shimmer {
  border-radius: 50%;
}
.formGr--shimmer .formLb--shimmer,
.formLb--shimmer {
  width: 70px;
  height: 12px;
  margin-bottom: 10px;
  margin-top: 8px;
}
.db__btns.db__btns-lg {
  min-width: 200px;
}
.formGr--shimmer .adminSettings--upload-del {
  width: 18px;
  height: 18px;
}
.dashHead--shimmer-alt {
  min-height: 40px;
}
.step--head-shimmer .step__text {
  width: 60px;
  height: 18px;
}
.step--head-shimmer .step__info {
  width: 100%;
  max-width: 140px;
  height: 18px;
}
.dashboard__paragraph-shimmer {
  width: 100%;
  max-width: 300px;
  height: 24px;
}
.uploadBlock--shimmer {
  display: flex;
  align-items: center;
  min-height: 28px;
}
.uploadBlock--shimmer .upBl__text {
  width: 100%;
  max-width: 188px;
  height: 22px;
}
.uploadBlock--shimmer .upBl__icon {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}
.uploadBlock--shimmer-alt {
  margin-top: 50px;
  margin-bottom: 36px;
  min-height: 72px;
}
.back__shimmer-lh {
  min-height: 25px;
}
.back__shimmer {
  display: flex;
  align-items: center;
}
.back__shimmer .back__shimmer-arrow {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.back__shimmer .back__shimmer-text {
  width: 40px;
  height: 18px;
}
.listing--schedule .hours--time .dropdown--shimmer {
  min-width: 110px;
}
.add__options-shimmer {
  min-width: 220px;
  min-height: 30px;
  border-radius: 0;
}
.form__para-shimmer {
  width: 100%;
  max-width: 500px;
  height: 20px;
  margin-top: 3px;
}
.formGr--shimmer .formGr--textarea {
  width: 100%;
  height: 190px;
  border-radius: 10px;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #f5f5f5 4%, #e2e2e2 25%, #f5f5f5 36%);
  background-size: 1000px 100%;
}
.animate--dark {
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #ededed 4%, #e2e2e2 25%, #ededed 36%);
  background-size: 1000px 100%;
}
.animate--gray {
  animation: shimmer 4s infinite linear;
  background: linear-gradient(to right, #e8ecef 4%, #e2e2e2 25%, #e8ecef 36%);
  background-size: 1000px 100%;
}
@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@media screen and (min-width: 992px) {
  .shimmer--dc {
    min-height: 520px;
  }
  .shimmer--floorplan {
    min-height: 550px;
  }
}
@media screen and (min-width: 1200px) {
  .media--video-block .media--video-shimmer {
    width: 220px;
  }
}
@media screen and (min-width: 1440px) {
  .media--video-block .media--video-shimmer {
    width: 330px;
  }
}
@media screen and (min-width: 1620px) {
  .listing__btn-shimmer {
    min-height: 130px;
  }
  .video--content .videoContent--place.videoContent--place-shimmer {
    height: 450px;
    border-radius: 20px;
  }
}
@media screen and (max-width: 1440px) {
  .pdf__btn-shimmer {
    padding: 10px 12px;
  }
  .pdf__btn-shimmer .pdf__btn-icon {
    margin-right: 12px;
    min-width: 24px;
    height: 28px;
  }
  .pdf__btn-shimmer .pdf__btn-text {
    height: 16px;
  }
  .block--search-row .photos--box-shimmer,
  .block--search-row .photos--lg-shimmer {
    height: 160px;
  }
  .pdf__shimmer-edit .pdf__btn-icon {
    width: 22px;
    height: 24px;
  }
}
@media screen and (max-width: 1200px) {
  .db--btns-shimmer {
    width: 100%;
  }
  .search--shimmer-lg .search__text {
    width: 80%;
  }
  .searchBs--shimmer .sb__shimmer-heading {
    width: 70%;
  }
  .db--shimmer {
    margin-top: 20px;
  }
}
@media screen and (max-width: 991px) {
  .alb--alt-shimmer .buildingBtn__shimmer {
    margin-left: 6px;
    margin-right: 6px;
  }
  .alb--alt-shimmer .al--listing-box-sh {
    margin-top: 14px;
  }
  .shimmer__heading-al {
    width: 75%;
  }
  .alb--lg-shimmer .al__address {
    margin-bottom: 20px;
  }
  .tab--shimmer .tabShimmer__text {
    display: none;
  }
  .bb--alt-shimmer .bb--inner-shimmer {
    width: 100%;
    margin-top: 12px;
  }
  .bb--alt-shimmer .bb--sf-shimmer .bb--inner-shimmer:first-child {
    align-items: flex-start;
    padding-right: 8px;
  }
  .bb--alt-shimmer .bb--sf-shimmer .bb--inner-shimmer:last-child {
    align-items: flex-end;
    padding-left: 8px;
  }
  .feature--shimmer .featureBlock__icon {
    width: 22px;
    height: 22px;
    margin-bottom: 28px;
  }
  .feature--shimmer .featureBlock__heading.text__gray,
  .feature--shimmer .sf__digit {
    height: 20px;
  }
  .feature--shimmer .featureShimmer--combine {
    margin-top: 20px;
  }
  .temp--options .db__btns {
    width: calc(100% - 16px);
  }
  .temp--options .db__btns:first-child {
    margin: 0px 8px 0px 0px;
  }
  .temp--options .db__btns:last-child {
    margin: 0px 0px 0px 8px;
  }
  .db--preview-lg {
    height: 28px;
  }
  .ct--shimmer .ct--head {
    min-height: 32px;
  }
  .ct--shimmer .ct--head .dashboard__heading {
    height: 28px;
  }
}
@media screen and (max-width: 767px) {
  .search--shimmer-lg {
    padding: 4px 15px;
    min-height: 56px;
  }
  .search--shimmer-lg .search__icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-right: 10px;
  }
  .search--shimmer-lg .search__text {
    height: 16px;
    width: 100%;
  }
  .searchBs--shimmer .sb__shimmer-img {
    margin: 0px auto 20px auto;
    height: 80px;
  }
  .searchBs--shimmer .search--bs-inner {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }
  .searchBs--shimmer .sb__shimmer-heading {
    width: 100%;
    max-width: 240px;
  }
  .searchBs--shimmer {
    flex-direction: column;
  }
  .searchBs--shimmer .search--bs-detail {
    flex-direction: column;
  }
  .searchBs--shimmer .bs__next {
    margin-left: auto;
  }
  .searchBs--shimmer-alt .search--bs-inner {
    margin-bottom: 14px;
    width: 100%;
    flex-direction: column;
  }
  .searchBs--shimmer-alt .sb__shimmer-heading {
    margin: 0px 0px 20px 0px;
  }
  .photos--lg-shimmer,
  .photos--box-shimmer {
    width: 120px;
    height: 120px;
    min-width: 120px;
  }
  .video--box-shimmer {
    width: 180px;
    min-width: 180px;
    height: 130px;
  }
  .viewBy--shimmer {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .shimmer__heading-al {
    width: 100%;
  }
  .media--video-block .media--video-shimmer {
    width: 100%;
    height: 320px;
    margin: 0px 0px 20px 0px;
  }
  .floorplanBox--shimmer .floorplanBox--img {
    height: 300px;
  }
  .location--box-shimmer .locationBox__heading {
    height: 18px;
  }
  .location--head-shimmer .location__destination,
  .location--head-shimmer .locationHead__logo {
    width: 120px;
    height: 30px;
  }
  .feature--shimmer .featureBlock__icon {
    margin-bottom: 22px;
  }
  .feature--block-address.feature--shimmer
    .featureShimmer--combine
    .featureBlock__heading:first-child {
    display: none;
  }
  .tourEdit--specs .specsEdit--shimmer .bw__heading,
  .specsEdit--shimmer .bw__heading,
  .specsEdit--shimmer .bw__para {
    height: 11px;
  }
  .certification--widget.specsEdit--shimmer .bw__icon {
    width: 32px;
    height: 32px;
  }
  .tourAnalyticBlock--shimmer .spotlight--head-shimmer {
    width: 100%;
  }
  .template--head-three .tempHeadTh--bg-shimmer {
    position: static;
    width: 100%;
    height: 500px;
  }
  .template--head-three .template--banner-shimmer .tempBanner--detail {
    padding-top: 94px;
    padding-bottom: 40px;
  }
  .ct--shimmer .ct--head .dashboard__heading {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 575px) {
  .btn-expand,
  .do--btn-canvas,
  .tl--shimmer .tourListing--row .para__md-dark,
  .tl--plus-shimmer .tourListing--row .para__md-dark,
  .tl--alt-shimmer .tourListing--row .para__md-dark,
  .db--btns-shimmer .db__btns {
    width: 100%;
  }
  .placement--shimmer-alt {
    min-height: 165px;
  }
  .db--shimmer {
    min-height: 60px;
  }
  .lt__shimmer {
    margin-left: 0;
    max-width: 76px;
  }
  .dashbaord--opt .button__shimmer {
    width: 100%;
    margin-top: 16px;
    margin-left: 0;
  }
  .button__shimmer .button__shimmer-text {
    max-width: 120px;
  }
  .photos--lg-shimmer {
    width: 100%;
    min-width: 10px;
  }
  .subdomain__footer-shimmer {
    min-height: 383px;
  }
  .do--btn-canvas .db__btns {
    margin-left: 0;
    width: 100%;
    margin-top: 16px;
  }
  .do--btn-canvas .db__btns.btn__lg-width {
    max-width: 100%;
  }
  .btnCanvas--column,
  .dbPreview--box {
    flex-direction: column;
    align-items: flex-start;
  }
  .floorplanBox--shimmer .floorplanBox--img {
    height: 250px;
  }
  .location--box-shimmer .locationBadge__shimmer {
    min-height: 30px;
    width: 75px;
    margin: 0px 4px 4px 0px;
  }
  .location--head-shimmer .locationHead__logo {
    margin-right: 10px;
  }
  .location--head-shimmer .location__destination {
    width: 86px;
    height: 24px;
    padding-left: 10px;
  }
  .editorBox--shimmer {
    height: 240px;
  }
  .feature--shimmer .featureBlock__icon {
    width: 16px;
    height: 16px;
    margin-bottom: 16px;
  }
  .feature--shimmer .featureBlock__heading.text__gray,
  .feature--shimmer .sf__digit {
    height: 14px;
    max-width: 90px;
  }
  .feature--shimmer .featureBlock__heading.text__dark {
    height: 14px;
  }
  .feature--shimmer .featureBlock--sf {
    margin-top: 4px;
  }
  .feature--shimmer .featureShimmer--combine {
    margin-top: 10px;
  }
  .pdf__shimmer-edit {
    min-height: 72px;
  }
  .pagination--shimmer {
    width: 100%;
  }
  .pagination--shimmer .paginationItem__shimmer:first-child {
    margin-left: 0;
  }
  .videoController--shimmer {
    flex-wrap: wrap;
  }
  .vcb--switch {
    flex: 0 0 70%;
    max-width: 70%;
    margin-left: 0;
    margin-top: 22px;
  }
  .videoController--shimmer .vcbIcon__shimmer:last-child {
    margin-top: 22px;
  }
  .video--content .videoContent--place.videoContent--place-shimmer {
    height: 180px;
    border-radius: 12px;
  }
  .tempCheck--shimmer .radioBtn--shimmer {
    justify-content: flex-start;
    margin-bottom: 2px;
  }
  .tempCheck--shimmer .tempCheck--frame {
    height: 280px;
  }
  .temp--about-12.temp--about-shimmer .tempAbout--img-box {
    height: 300px;
  }
  .temp--about-shimmer.temp--specification .tempAbout--btn-canvas {
    margin-top: 56px;
  }
  .template--head-three .template--banner-shimmer .tempLink--canvas {
    align-items: flex-start;
  }
  .tempLink--canvas .link--shimmer {
    margin: 30px 0px 0px 0px;
  }
  .tempCombine--head-shimmer .tch--heading,
  .temp--about-shimmer .heading__lg-white {
    height: 22px;
  }
  .tempCombine--head-shimmer .tch--text {
    height: 16px;
  }
  .template--head-two .template--banner-shimmer .row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .template--head-two .template--banner-shimmer .row .col {
    padding-left: 0;
    padding-right: 0;
  }
  .template--banner-shimmer .tempBanner--btn-canvas {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .template--banner-shimmer .tempBanner--btn-canvas .db__btns {
    width: 100%;
  }
  .sustainBox--shimmer-alt .sbs--text .heading__md-dark {
    height: 24px;
  }
  .suite--block-shimmer .suite--detail .heading__th-dark {
    height: 20px;
  }
  .template--banner-shimmer .shimmer__heading-lg {
    height: 22px;
    max-width: 80%;
  }
  .template--banner-shimmer .shimmer__heading-md {
    height: 18px;
  }
  .temp--options .db__btns {
    width: 100%;
  }
  .temp--options .db__btns:first-child {
    margin: 0px 0px 16px 0px;
  }
  .temp--options .db__btns:last-child {
    margin: 0px;
  }
  .db--preview-lg {
    height: 24px;
  }
  .listingToured--nav-shimmer {
    width: 100%;
  }
  .listingToured--nav-shimmer .ltn__item {
    flex: 1;
  }
  .createTour--inner-shimmer {
    min-height: 30px;
  }
  .createTour--inner-shimmer .createTour--card-heaing {
    height: 22px;
  }
  .dashboard--head-shimmer .dashboard__heading {
    height: 26px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .photos--box-shimmer,
  .video--box-shimmer {
    width: 100%;
    min-width: 10px;
  }
  .timeBox--options-shimmer .to--box:first-child .radioBtn--shimmer {
    justify-content: flex-start;
  }
  .timeBox--options-shimmer .to--box:last-child .radioBtn--shimmer {
    justify-content: flex-end;
  }
  .editor--shimmer .editorOpt__shimmer {
    margin-right: 10px;
    width: 100%;
  }
  .videoContent--options-shimmer .videoContent__speed-btn {
    min-height: 24px;
  }
  .tour__badge-shimmer {
    min-width: 248px;
  }
}
