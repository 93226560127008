/* write custom css here */
button:disabled {
  cursor: not-allowed;
}
.cursor-pointer {
  cursor: pointer;
}
/*
  Loader
*/
.lds-canvas {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 555;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
}
.lds-canvas .lds-spinner {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
}
.lds-canvas .lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-canvas .lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 25px;
  left: 39px;
  width: 2px;
  height: 10px;
  border-radius: 33%;
  background: #000000;
}
.lds-spinner {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
}
.lds-spinner div {
  transform-origin: 9px 8px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  width: 1px;
  height: 5px;
  border-radius: 20%;
  background: #000000;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 Loader
*/
.personalize--block {
  border-radius: 20px;
}
.footer__logo {
  min-width: 32px;
}
.app__button .lds-spinner {
  padding-top: 1px;
  padding-left: 4px;
}
.app__button:hover .lds-spinner div:after,
.app__button:focus .lds-spinner div:after,
.app__button:active .lds-spinner div:after {
  background: #1c1f20;
}
.app__button .lds-spinner div:after {
  background: #fff;
}
.signin--page .app__button .lds-spinner div:after {
  background-color: #1c1f20;
}
.admin__button .lds-spinner,
.schedule__btn .lds-spinner,
.outline__button .lds-spinner {
  padding-left: 4px;
  padding-top: 1px;
}
.admin__button .lds-spinner div:after,
.schedule__btn .lds-spinner div:after,
.profile--img-upload .lds-spinner div:after {
  background: #fff;
}

.img--cover-modern {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.admin__button_listing {
  text-decoration: none;
  color: #fff;
}
admin__button_listing:hover {
  text-decoration: none;
  color: #000000;
}
.schedule__btn:hover .lds-spinner div:after,
.schedule__btn:focus .lds-spinner div:after,
.schedule__btn:active .lds-spinner div:after {
  background: #f2e9d0;
}
.profile--img-upload .lds-spinner {
  padding-left: 3px;
  padding-top: 5px;
}
.adminSettings--upload-item .lds-spinner {
  padding-left: 3px;
}
.forms--group .lds-spinner {
  position: absolute;
  right: 6px;
  bottom: 14px;
}
.outline__button:hover .lds-spinner div:after,
.outline__button:focus .lds-spinner div:after,
.outline__button:active .lds-spinner div:after {
  background: #fff;
}
.form--group-alt .error__msg,
.fields--group .error__msg {
  position: absolute;
  left: 0;
  top: 52px;
  color: #e7063c;
  font-size: 12px;
}
.checkbox--set {
  min-width: 250px;
}
.checkbox--set .error__msg {
  position: absolute;
  left: 0;
  top: 26px;
  color: #e7063c;
  font-size: 12px;
}
.custom__file .error__msg {
  position: absolute;
  left: 0;
  top: 32px;
  color: #e7063c;
  font-size: 12px;
}
.uploadPhoto--progress .uploadPhoto__error {
  background-color: red;
}
.spinner--group .form-control {
  padding-right: 32px;
}
.spinner--group .lds-spinner {
  position: absolute;
  right: 11px;
  top: 16px;
}
.spinner--group .lds-spinner div:after {
  background: #000;
}
.spinner--group.listing--dropdown .lds-spinner {
  right: 30px;
}
.google--suggestions--container {
  padding: 4px 0;
  font-size: 14px;
  border: 1px solid #0638e7;
  position: absolute;
  left: 0;
  right: 0;
  top: 47px;
  background-color: #fff;
  z-index: 5;
  max-height: 240px;
  overflow-y: auto;
}
.google--suggestions--container::-webkit-scrollbar {
  width: 3px;
}
.google--suggestions--container::-webkit-scrollbar-thumb {
  background: #bcbab8;
}
.listing--dropdown-pad .dropdown-toggle {
  padding-right: 48px;
}
.google--suggestions--container .google--places--suggestions {
  line-height: 20px;
  padding: 10px 13px;
  color: #6a7074;
}
.google--suggestions--container .google--places--suggestions:hover {
  background: #0638e7;
  color: #fff;
  cursor: pointer;
}
.step--upload-block .lds-spinner {
  padding-top: 5px;
}
.step--upload-block .lds-spinner div:after {
  background: #000;
}
.listing--dropdown .error {
  border: 1px solid #e7063c;
}
.combine--group .error__msg {
  position: absolute;
  top: 52px;
  left: 0;
  color: #e7063c;
  font-size: 12px;
}

.combine--group .error__msg.edit {
  top: 80px;
}
.spinner--group.select--group .lds-spinner {
  right: 30px;
}
.spinner--group.select--group .form-control {
  padding-right: 52px;
}
/*.dashboard--content .lds-canvas .lds-spinner div:after {
  background: #0638E7;
}
.listing .lds-canvas .lds-spinner div:after {
  background: #0638E7;
}*/
.al--block {
  cursor: pointer;
}
.loading--more {
  text-align: center;
}
.loading--more .lds-spinner {
  padding-top: 5px;
  padding-left: 6px;
}
.loading--more .lds-spinner div:after {
  background: #000000;
}
.options__list li a .lds-spinner {
  padding-left: 5px;
}
.options__list li a:hover .lds-spinner div:after,
.options__list li a:focus .lds-spinner div:after {
  background: #0638e7;
}
/* .listing-video {
  border: 2px solid;
  box-shadow: 2px 2px 10px;
}
.listing-video:before {
  position: relative !important;
} */
.building-single-listing {
  font-weight: normal;
}
div.css-1hb7zxy-IndicatorsContainer > span {
  display: none;
}
div.css-yk16xz-control,
div.css-1pahdxg-control {
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #bcbab8;
  min-height: 48px !important;
  padding: 6px 12px !important;
}
div.css-yk16xz-control:hover {
  border-color: #bcbab8 !important;
}
div.css-1pahdxg-control:hover,
div.css-1pahdxg-control {
  border-color: #0638e7 !important;
}
.css-1wa3eu0-placeholder {
  font-size: 14px;
  color: #6a7074 !important;
}
.css-1hwfws3 {
  padding: 2px 10px !important;
}
.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
  padding: 4px !important;
}
.css-26l3qy-menu {
  border-radius: 0px !important;
  border: none !important;
  box-shadow: 0px 1px 2px rgba(34, 39, 44, 0.1) !important;
  z-index: 55 !important;
}
.css-1uccc91-singleValue {
  font-size: 14px;
  color: #222222;
}
.css-9gakcf-option {
  font-size: 14px !important;
  color: #222222 !important;
  word-break: break-word;
  background-color: #deebff !important;
}
.css-1rhbuit-multiValue {
  border-radius: 0px !important;
  background-color: #1c1f20 !important;
}
.css-g1d714-ValueContainer {
  padding: 0 !important;
}
.css-12jo7m5 {
  font-size: 14px !important;
  padding-left: 8px !important;
  line-height: 20px !important;
  color: #fff !important;
}
.css-xb97g8 {
  border-radius: 0px !important;
  cursor: pointer;
  color: #fff !important;
}
.css-xb97g8:hover {
  background: transparent !important;
  color: #fff !important;
}
.css-4ljt47-MenuList {
  overflow-x: hidden;
}
.css-gg45go-NoOptionsMessage {
  font-size: 14px !important;
  color: #8e91a0 !important;
}
.css-yt9ioa-option,
.css-1n7v3ny-option,
.css-165luzk-option,
.css-19jh2ze-option {
  font-size: 14px !important;
  color: #8e91a0 !important;
  word-break: break-word;
}
.css-yt9ioa-option:focus,
.css-1n7v3ny-option:focus,
.css-165luzk-option:focus,
.css-19jh2ze-option:focus {
  outline: none !important;
}
.css-4ljt47-MenuList::-webkit-scrollbar {
  width: 5px;
}
.css-4ljt47-MenuList::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.css-4ljt47-MenuList::-webkit-scrollbar-thumb {
  background: #e44d43;
  border-radius: 10px;
}
.css-1pahdxg-control:hover {
  border-color: white !important;
}
.css-1hwfws3 > div {
  border-radius: 22px !important;
}
.css-1hwfws3 .css-1wa3eu0-placeholder {
  background-color: transparent;
}
.css-1hwfws3 .css-b8ldur-Input {
  line-height: 18px;
  background-color: transparent;
}
div.css-tlfecz-indicatorContainer > svg.css-6q0nyr-Svg,
div.css-1gtu0rj-indicatorContainer > svg.css-6q0nyr-Svg {
  background: #1c1f20;
  border-radius: 0;
  color: white;
}
.css-a25fsq-multiValue svg.css-6q0nyr-Svg {
  color: white;
  width: 16px;
  height: 16px;
}
select {
  overflow: hidden;
  text-overflow: ellipsis;
}
select option {
  white-space: nowrap;
}
.textarea--group .error__msg,
.errorMsg--set .error__msg {
  top: initial;
  bottom: -20px;
}
.listing--dropdown .dropdown-item.active {
  background-color: #0638e7;
  color: #fff;
}
.requestTour--box .forms--group .error__msg {
  font-size: 11px;
}
.listing-map-view {
  height: 100%;
  width: 100%;
}
.adminSettings--upload--logo .error__msg {
  position: absolute;
  top: 80px;
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.adminSettings--row .forms--group .error__msg {
  top: 80px;
}
.adminSettings--row .adminSettings--upload--logo .error__msg {
  padding-left: 12px;
}
.error__msg {
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.virtual--video--active {
  position: relative;
  overflow: visible;
  z-index: 5;
}
@keyframes highlight {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.025);
  }
  from {
    transform: scale(1);
  }
}
.virtual--video--active::after {
  content: '';
  position: absolute;
  transform-origin: center center;
  height: 100%;
  width: 100%;
  animation-name: highlight;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  z-index: -1;
  background-color: #7ff87b;
}
.virtual--video-placeholder {
  background: black;
  height: 100%;
  width: 100%;
}
.bg-white-button {
  background-color: white;
}
.gallery--carousel {
  height: 440px;
}
.join--tour .error__msg {
  top: 56px;
}
.placeholder {
  color: lightgray;
}
.error__msg-white {
  color: white !important;
}
.liveTour--page {
  background: #1c1f20;
}
.ca--group-main .error__msg {
  font-size: 12px;
  color: red;
  position: absolute;
}
.ca--group-main .edited__msg {
  font-size: 12px;
}
.custom__file .lds-spinner {
  padding-left: 4px;
}
.ca--cover {
  cursor: pointer;
}
.outline__button:disabled {
  border: 1px solid #222222;
  background-color: transparent;
  color: #222222;
}
.record__voice .lds-spinner {
  margin-top: 2px;
}
.record__voice .lds-spinner div:after {
  background: #0638e7;
}
.record__voice:hover .lds-spinner div:after {
  background: white;
}
.transparent--overlay {
  bottom: 33px;
  background-color: transparent;
}
.hidden--overlay {
  top: initial;
  bottom: 0;
  height: 40px;
  background-color: transparent;
}
.status-text {
  text-align: center;
  margin-bottom: 0px;
}
.meeting--block {
  cursor: pointer;
}
.quill--group .ql-container {
  min-height: 150px !important;
  max-height: 300px !important;
  border: 1px solid #bcbab8 !important;
  border-radius: 0 !important;
  color: #222222 !important;
  background-color: transparent !important;
  height: auto !important;
}
.quill--group .ql-editor {
  min-height: 150px !important;
  max-height: 300px !important;
  color: #222222 !important;
  font-size: 14px !important;
  height: auto !important;
}
.quill--group .ql-editor::-webkit-scrollbar {
  width: 4px !important;
}
.quill--group .ql-editor::-webkit-scrollbar-thumb {
  background-color: #bcbab8 !important;
  border-radius: 10px !important;
}
.hide--section {
  position: fixed !important;
  bottom: -10000px !important;
  left: -10000px !important;
  opacity: 0 !important;
  pointer-events: none !important;
}
tourListingLoading {
  position: absolute;
}
.liveTour__list {
  overflow-x: initial;
}
.liveTour--listing .container--fixed {
  height: 100%;
  overflow: hidden;
}
.ls--row .loading--more {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  max-width: 75px;
  text-align: center;
}
.ls--row .loading--more .lds-spinner {
  padding: 0;
}
.ls--row .loading--more span {
  font-size: 14px;
  color: #6a7074;
}

.lt__list_span {
  display: inline-flex;
  margin-top: -12px;
  margin-bottom: -12px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.upcomingTour--canvas {
  position: relative;
}
.upcomingTour--canvas .lds-canvas {
  position: absolute;
}
.upcomingTour--canvas .loading--more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.upcomingTour--canvas .loading--more .lds-spinner {
  padding-top: 0;
}
.loading-fixed {
  margin-top: -24px;
}
.white--rounded-box {
  position: relative;
}
.white--rounded-box .lds-canvas {
  position: absolute;
}
.dashboard__btn .lds-spinner {
  margin-left: auto;
}
.dashboard__btn .lds-spinner div:after {
  top: 12px;
  bottom: 0;
  left: 8px;
  width: 2px;
  height: 8px;
}
.dashboard__btn:hover .lds-spinner div:after,
.dashboard__btn:focus .lds-spinner div:after {
  background-color: #fff;
}

.empty--photos__control-prev {
  margin-right: 3.2%;
}
.empty--photos__control-next {
  margin-left: 3.2%;
}
.form-option--radius .form-control {
  border-radius: 10px;
}
.no-height-implement {
  height: auto !important;
}
.subListing--video {
  cursor: pointer;
}
.listing-map-view {
  border-radius: 16px;
}
.cap_title:first-letter {
  text-transform: uppercase;
}
.forms--group-radius .ql-toolbar {
  border-radius: 10px 10px 0 0 !important;
}
.forms--group-radius .ql-container {
  border-radius: 0 0 10px 10px !important;
}
.forms--group-radius .ant-input,
.forms--group-radius .google-places-autocomplete,
.forms--group-radius .dropdown-toggle {
  border-radius: 10px !important;
}
.forms--group-radius .error .ant-input,
.forms--group-radius .error .ant-input:hover,
.forms--group-radius .error .ant-input:focus,
.forms--group-radius .error .ant-input:active {
  border-color: #e7063c;
}
.quill.error .ql-toolbar {
  border-top-color: #e7063c !important;
  border-right-color: #e7063c !important;
  border-left-color: #e7063c !important;
}
.quill.error .ql-container {
  border-bottom-color: #e7063c !important;
  border-right-color: #e7063c !important;
  border-left-color: #e7063c !important;
}
.ql-editor-toolbar .ql-toolbar {
  border: none !important;
}
.listing--description-editor .ql-container {
  height: 380px !important;
  border-radius: 10px !important;
  border: none !important;
  background-color: #f6f6f6 !important;
}
.listing--description-editor .ql-editor {
  height: 380px !important;
}
.listing--description-editor .ql-editor::-webkit-scrollbar-thumb {
  background-color: #000 !important;
}
.listing--description-editor .quill.error .ql-container {
  border: 1px solid #e7063c !important;
}
.subdomain--gallery-video video {
  object-fit: cover;
}
.subdomain--gallery-playIcon {
  position: absolute;
  color: white;
  font-size: 30px;
}
.handler-buttons {
  position: relative;
}
.handler-buttons:disabled {
  background-color: white;
}
.handler-buttons:hover:disabled {
  background-color: hsl(227deg 95% 46%);
}
.loading--state {
  position: absolute;
  left: 0px;
  height: 100%;
  top: 0px;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 555;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.22);
}
.listing__btn-row .col {
  max-width: 50%;
}
.google-places-autocomplete .error.form-control {
  border-color: #e7063c;
}
.listing--dropdown-pad .dropdown--button-radius {
  border-radius: 10px;
}
.forms--group .adminSettings--upload-item {
  border-radius: 10px;
}
.forms--group .adminSettings--upload-item.error {
  border-color: #e7063c;
}
.underline__button:after {
  content: unset;
}
.interface--dropdown .dropdown-menu {
  border-radius: 10px 10px 0 0;
}
.listing--dropdown-pad .dropdown--button-radius.opened {
  border-radius: 0 0 10px 10px;
}
.amenities--box .specs__label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
}
.specs--box-alt .specs__smText {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.component--edit .componentEdit--scrollable form {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}
.media--video-block .media--video {
  cursor: pointer;
}
.magnifier-tools {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.magnifier-zoom {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  color: #1c1f20;
  padding: 2px;
  margin: 8px 0;
  box-shadow: 0px 0px 10px rgba(97, 94, 94, 0.3);
}
.magnifier-zoom:not(:disabled):not(.disabled):active,
.magnifier-zoom:hover,
.magnifier-zoom:focus,
.magnifier-zoom:active {
  background-color: #828282;
  border: none;
  box-shadow: 0px 0px 10px rgba(97, 94, 94, 0.3);
  color: #1c1f20;
}
.liveTour--gallery-large .TransformComponent-module_container__3NwNd {
  overflow: visible !important;
}
.sketch-picker {
  max-width: 100% !important;
  width: initial !important;
  position: absolute !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 99;
  margin-bottom: 5px !important;
}
.adminSettings--row .color--group .error__msg {
  top: 50px;
}
.react-pdf__message--loading {
  margin: auto;
  display: flex;
  align-items: center;
}
.react-pdf__message--loading .lds-spinner {
  margin-left: 4px;
}
.box-cursor {
  cursor: pointer;
}
.specsOption--box .cancel_spec--icon {
  background-color: #6a7074;
}
.specsOption--box .cancel_spec--text {
  color: #6a7074;
}
.specsOption--box:hover .cancel_spec--icon,
.specsOption--box:focus .cancel_spec--icon {
  background-color: #1c1f20;
}
.specsOption--box:hover .cancel_spec--text,
.specsOption--box:focus .cancel_spec--text {
  color: #1c1f20;
}
.specs--box-alt .specs--box-base-alt {
  height: 48px;
  align-items: initial;
}
.amenities--box.amenities--box-alt {
  height: 104px;
}
.specs--box-alt .specs__label-alt,
.amenities--box .specs__label-alt {
  display: inline-block;
  word-break: break-word;
}
.specs--box-alt .specs--box-base-alt.listing--details {
  height: 40px;
}
.react-transform-component {
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
}
.react-transform-element {
  width: 100% !important;
  height: 100% !important;
  flex-wrap: initial !important;
  display: inline-block !important;
  text-align: center !important;
}
.react-transform-component .react-transform-element img {
  height: 100% !important;
  width: 100% !important;
}
.uploadPhoto--component {
  position: relative;
}
.uploadPhoto--component .error__msg {
  position: absolute;
  left: 10px;
  top: 315px;
  color: #e7063c;
  font-size: 12px;
  margin-bottom: 0;
}
.border-radius-10 {
  border-radius: 10px;
}
.draggable--canvas .tourEdit--listing:last-child {
  margin-right: 0;
}

.neighbour-scroll {
  overflow: auto;
}
.neighbour-scroll .accordion {
  min-height: auto;
  min-width: auto;
  max-height: none;
}
.neighbour-scroll .accordion .collapse {
  max-height: none;
}
.heading-shimmer-neighbour {
  width: 30%;
  height: 30px;
}
.hide-after-right {
  margin-right: 40px;
}
.hide-after-left {
  margin-left: 40px;
}
.hide-after-slide::after,
.hide-after-slide::before {
  display: none;
}
.no-max-width {
  max-width: none;
  margin-left: 0px;
  margin-right: 0px;
}
.static-tour-video-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  display: flex;
}
.static-video-player div video {
  object-fit: initial;
}
.video--content .videoContent--place {
  height: auto;
  overflow: initial;
  border-radius: 0;
  margin: 0;
}
.video--content .videoContent--place .rr-player {
  width: 100% !important;
  height: 100% !important;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}
.video--content .videoContent--place .rr-player__frame {
  border: 1px solid #bcbab8;
  width: 100% !important;
  height: 408px !important;
  overflow: hidden;
  background-color: #e5e5e5;
  border-radius: 20px;
  margin-bottom: 22px;
}
.video--content .videoContent--place .replayer-wrapper {
  transform: scale(0.34) translate(-50%, -50%) !important;
}
.video--content .rr-controller.svelte-dxnc1j.svelte-dxnc1j {
  background: transparent;
  height: auto;
}
.video--content .rr-timeline.svelte-dxnc1j.svelte-dxnc1j {
  width: 100%;
  margin-bottom: 22px;
}
.video--content .rr-timeline__time.svelte-dxnc1j.svelte-dxnc1j {
  font-size: 14px;
  color: #6a7074;
  width: auto;
}
.video--content .rr-progress.svelte-dxnc1j.svelte-dxnc1j {
  margin: 0 20px;
  border: none;
  height: 5px;
  border-radius: 20px;
}
.video--content .rr-controller__btns.svelte-dxnc1j.svelte-dxnc1j {
  width: 100%;
  justify-content: space-between;
}
.video--content .switch.svelte-1mmdovf.svelte-1mmdovf {
  height: auto;
  font-size: 13px;
  color: #6a7074;
  margin-left: 12px;
}
.video--content .switch.svelte-1mmdovf label.svelte-1mmdovf {
  margin-bottom: 0;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j {
  color: #0638e7;
  font-size: 14px;
  margin-left: 2px;
  margin-right: 2px;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.active.svelte-dxnc1j {
  color: #fff;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j svg {
  fill: #0638e7;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j:first-child {
  margin-left: 0;
  margin-right: auto;
}
.video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j:last-child {
  margin-left: auto;
  margin-right: 0;
}
.video--content .rr-progress__handler.svelte-dxnc1j.svelte-dxnc1j {
  display: none;
}
.video--content .videoContent--place.video--fullscreen .rr-player__frame {
  height: calc(100% - 120px) !important;
}
.video--content .videoContent--place.video--fullscreen .replayer-wrapper {
  transform: scale(0.7) translate(-50%, -50%) !important;
}
.video--content .video--fullscreen .rr-controller.svelte-dxnc1j.svelte-dxnc1j {
  padding-left: 20px;
  padding-right: 20px;
}
.dashboard--accordian > .dashboard--card-alt {
  overflow: initial;
}
.ant-message svg {
  vertical-align: initial;
}
.temp--group .ql-editor {
  max-height: 228px !important;
}
.ql-toolbar.ql-snow .cross--icon {
  font-size: 12px !important;
  margin-top: 5px !important;
  margin-right: 6px !important;
  cursor: pointer !important;
}
.tempParaCombine--canvas.para__md-white .small__paragraph {
  color: #fff !important;
}
.tempParaCombine--canvas.para__md-dark .small__paragraph {
  color: #1c1f20 !important;
}
.tempParaCombine--canvas.para__md-gray .small__paragraph {
  color: #6a7074 !important;
}

.para__md-gray-center {
  color: #6a7074 !important;
  text-align: center;
}
.temp--about-1 .ql-toolbar .ql-stroke {
  fill: none !important;
  stroke: #fff !important;
}
.temp--about-1 .ql-toolbar .ql-fill {
  fill: #fff !important;
  stroke: none !important;
}
.temp--about-1 .ql-editor h1,
.temp--about-1 .ql-editor h2,
.temp--about-1 .ql-editor h3,
.temp--about-1 .ql-editor h4,
.temp--about-1 .ql-editor h5,
.temp--about-1 .ql-editor h6,
.temp--about-1 .ql-editor span,
.temp--about-1 .ql-editor b,
.temp--about-1 .ql-editor i,
.temp--about-1 .ql-editor label .temp--about-1 .ql-editor div {
  color: #fff !important;
}
.temp--about-1 .ql-toolbar .ql-picker,
.temp--about-1 .ql-toolbar .ql-picker-options,
.temp--about-1 .quill--group .ql-editor {
  color: #fff !important;
  background-color: #1c1f20 !important;
}

.ls--row .ls--col {
  min-width: fit-content;
}

@media screen and (min-width: 1440px) {
  .video--content .videoContent--place .replayer-wrapper {
    transform: scale(0.46) translate(-50%, -50%) !important;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.82) translate(-50%, -50%) !important;
  }
}
@media screen and (min-width: 1620px) {
  .video--content .videoContent--place .replayer-wrapper {
    transform: scale(0.54) translate(-50%, -50%) !important;
  }
  .video--content .videoContent--place .rr-player__frame {
    height: 450px !important;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(1.1) translate(-50%, -50%) !important;
  }
}
@media screen and (max-width: 1200px) {
  .gallery--carousel {
    height: 672px;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.52) translate(-50%, -50%) !important;
  }
}
@media screen and (max-width: 991px) {
  .gallery--carousel {
    height: 440px;
  }
  .listing-sm-map {
    height: 420px;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.4) translate(-50%, -50%) !important;
  }
}
@media screen and (max-width: 767px) {
  .gallery--carousel {
    height: 672px;
  }
  .magnifier-tools {
    right: 10px;
  }
}
@media screen and (max-width: 575px) {
  .video--content .videoContent--place .replayer-wrapper {
    transform: scale(0.18) translate(-50%, -50%) !important;
  }
  .video--content .videoContent--place .rr-player__frame {
    height: 180px !important;
    border-radius: 12px;
  }
  .video--content .rr-controller__btns.svelte-dxnc1j.svelte-dxnc1j {
    flex-wrap: wrap;
  }
  .video--content .switch.svelte-1mmdovf.svelte-1mmdovf {
    margin-top: 10px;
    margin-left: 0;
    flex: 0 0 70%;
    max-width: 70%;
  }
  .video--content .rr-controller__btns.svelte-dxnc1j button.svelte-dxnc1j:last-child {
    margin-top: 10px;
    flex: 0 0 30%;
    max-width: 30%;
    display: flex;
    justify-content: flex-end;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.3) translate(-50%, -50%) !important;
  }
  .preContent--location .gm-style .gm-control-active.gm-fullscreen-control {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .gallery--carousel {
    height: auto;
  }
  .video--content .videoContent--place .replayer-wrapper {
    transform: scale(0.15) translate(-50%, -50%) !important;
  }
  .video--content .videoContent--place.video--fullscreen .replayer-wrapper {
    transform: scale(0.2) translate(-50%, -50%) !important;
  }
}
