@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?v3io3w');
  src: url('../fonts/icomoon.eot?v3io3w#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?v3io3w') format('truetype'),
    url('../fonts/icomoon.woff?v3io3w') format('woff'),
    url('../fonts/icomoon.svg?v3io3w#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu-ham-bordered:before {
  content: '\e9ca';
}
.icon-add-group:before {
  content: '\e900';
}
.icon-angle-left:before {
  content: '\e901';
}
.icon-arrow-down:before {
  content: '\e902';
}
.icon-arrow-down-alt:before {
  content: '\e903';
}
.icon-arrow-left:before {
  content: '\e904';
}
.icon-arrow-left-alt:before {
  content: '\e905';
}
.icon-arrow-right:before {
  content: '\e906';
}
.icon-arrow-right-alt:before {
  content: '\e907';
}
.icon-arrow-to-bottom:before {
  content: '\e908';
}
.icon-arrow-up:before {
  content: '\e909';
}
.icon-arrow-up-alt:before {
  content: '\e90a';
}
.icon-at:before {
  content: '\e90b';
}
.icon-audio:before {
  content: '\e90c';
}
.icon-average-floor:before {
  content: '\e90d';
}
.icon-bank-building:before {
  content: '\e90e';
  color: #15235c;
}
.icon-bars:before {
  content: '\e90f';
}
.icon-bolt:before {
  content: '\e910';
}
.icon-brush:before {
  content: '\e911';
}
.icon-building:before {
  content: '\e912';
}
.icon-building-alt:before {
  content: '\e913';
}
.icon-buildings:before {
  content: '\e914';
}
.icon-bus:before {
  content: '\e915';
}
.icon-business:before {
  content: '\e916';
}
.icon-calendar:before {
  content: '\e917';
}
.icon-camera:before {
  content: '\e918';
}
.icon-camera-alt:before {
  content: '\e919';
}
.icon-caret-down:before {
  content: '\e91a';
}
.icon-caret-up:before {
  content: '\e91b';
}
.icon-chart-line:before {
  content: '\e91c';
}
.icon-chat:before {
  content: '\e91d';
  color: #fff;
}
.icon-check:before {
  content: '\e91e';
}
.icon-check-circle:before {
  content: '\e91f';
}
.icon-chevron-bottom:before {
  content: '\e920';
}
.icon-chevron-down-alt:before {
  content: '\e921';
}
.icon-chevron-down-solid:before {
  content: '\e922';
}
.icon-chevron-left:before {
  content: '\e923';
}
.icon-chevron-left-alt:before {
  content: '\e924';
}
.icon-chevron-right:before {
  content: '\e925';
}
.icon-chevron-right-alt:before {
  content: '\e926';
}
.icon-chevron-top:before {
  content: '\e927';
}
.icon-chevron-top-alt:before {
  content: '\e928';
}
.icon-chevron-up-solid:before {
  content: '\e929';
}
.icon-circle:before {
  content: '\e92a';
}
.icon-clap:before {
  content: '\e92b';
}
.icon-clipboard-list:before {
  content: '\e92c';
}
.icon-clock:before {
  content: '\e92d';
}
.icon-clock-alt:before {
  content: '\e92e';
}
.icon-clock-standard:before {
  content: '\e92f';
}
.icon-comment-dots:before {
  content: '\e930';
}
.icon-compress:before {
  content: '\e931';
}
.icon-compress-alt:before {
  content: '\e932';
}
.icon-compress-wide:before {
  content: '\e933';
}
.icon-condition:before {
  content: '\e934';
}
.icon-copyright:before {
  content: '\e935';
}
.icon-cross:before {
  content: '\e936';
}
.icon-cross-alt:before {
  content: '\e937';
}
.icon-cross-cirlce:before {
  content: '\e938';
}
.icon-cursor:before {
  content: '\e939';
}
.icon-cycling:before {
  content: '\e93a';
}
.icon-dashboard:before {
  content: '\e93b';
}
.icon-dashboard-alt:before {
  content: '\e93c';
}
.icon-description:before {
  content: '\e93d';
}
.icon-desktop:before {
  content: '\e93e';
}
.icon-details:before {
  content: '\e93f';
}
.icon-disk:before {
  content: '\e940';
}
.icon-dot-circle:before {
  content: '\e941';
}
.icon-edit:before {
  content: '\e942';
}
.icon-edit-alt:before {
  content: '\e943';
}
.icon-edit-custom:before {
  content: '\e944';
}
.icon-egg-box:before {
  content: '\e945';
}
.icon-ellipsis-h:before {
  content: '\e946';
}
.icon-ellipsis-v:before {
  content: '\e947';
}
.icon-envelope:before {
  content: '\e948';
}
.icon-expand:before {
  content: '\e949';
}
.icon-expand-alt:before {
  content: '\e94a';
}
.icon-external-link:before {
  content: '\e94b';
}
.icon-eye-alt:before {
  content: '\e94c';
}
.icon-eye-custom:before {
  content: '\e94d';
}
.icon-eye-slash-alt:before {
  content: '\e94e';
}
.icon-facebook:before {
  content: '\e94f';
}
.icon-facebook-square:before {
  content: '\e950';
}
.icon-file:before {
  content: '\e951';
}
.icon-floorplan:before {
  content: '\e952';
}
.icon-floorplan-alt:before {
  content: '\e953';
}
.icon-freight-elevator:before {
  content: '\e954';
}
.icon-gallery:before {
  content: '\e955';
}
.icon-gallery-upload:before {
  content: '\e956';
}
.icon-globe:before {
  content: '\e957';
}
.icon-grid-horizontal:before {
  content: '\e958';
}
.icon-grid-vertical:before {
  content: '\e959';
}
.icon-heart:before {
  content: '\e95a';
}
.icon-heart-fill:before {
  content: '\e95b';
}
.icon-help:before {
  content: '\e95c';
}
.icon-home:before {
  content: '\e95d';
}
.icon-instagram:before {
  content: '\e95e';
}
.icon-integration:before {
  content: '\e95f';
  color: #341062;
}
.icon-Itinerary:before {
  content: '\e960';
}
.icon-join:before {
  content: '\e961';
}
.icon-key:before {
  content: '\e962';
}
.icon-keys:before {
  content: '\e963';
}
.icon-layers:before {
  content: '\e964';
}
.icon-library:before {
  content: '\e965';
}
.icon-lightbulb:before {
  content: '\e966';
}
.icon-link:before {
  content: '\e967';
}
.icon-linkedin:before {
  content: '\e968';
}
.icon-linkedin-square:before {
  content: '\e969';
}
.icon-list:before {
  content: '\e96a';
}
.icon-live-integrate:before {
  content: '\e96b';
}
.icon-location:before {
  content: '\e96c';
}
.icon-lock:before {
  content: '\e96d';
}
.icon-lock-alt:before {
  content: '\e96e';
}
.icon-lock-fill:before {
  content: '\e96f';
}
.icon-login:before {
  content: '\e970';
}
.icon-logout:before {
  content: '\e971';
}
.icon-mail:before {
  content: '\e972';
}
.icon-management:before {
  content: '\e973';
}
.icon-map-marker:before {
  content: '\e974';
}
.icon-map-marker-alt:before {
  content: '\e975';
}
.icon-map-marker-light:before {
  content: '\e976';
}
.icon-menu:before {
  content: '\e977';
}
.icon-metro:before {
  content: '\e978';
}
.icon-microphone:before {
  content: '\e979';
}
.icon-microphone-slash:before {
  content: '\e97a';
}
.icon-minus:before {
  content: '\e97b';
}
.icon-minus-circle:before {
  content: '\e97c';
}
.icon-mobile:before {
  content: '\e97d';
}
.icon-month:before {
  content: '\e97e';
}
.icon-mute:before {
  content: '\e97f';
}
.icon-notification:before {
  content: '\e980';
}
.icon-organisation:before {
  content: '\e981';
}
.icon-paperclip:before {
  content: '\e982';
}
.icon-parking-ratio:before {
  content: '\e983';
}
.icon-passenger-elevator:before {
  content: '\e984';
}
.icon-pause:before {
  content: '\e985';
}
.icon-pause-circle:before {
  content: '\e986';
}
.icon-pdf:before {
  content: '\e987';
}
.icon-pdf-alt:before {
  content: '\e988';
}
.icon-pencil-fill:before {
  content: '\e989';
}
.icon-phone:before {
  content: '\e98a';
}
.icon-platform:before {
  content: '\e98b';
}
.icon-play:before {
  content: '\e98c';
}
.icon-play-circle:before {
  content: '\e98d';
}
.icon-play-outline:before {
  content: '\e98e';
}
.icon-play-video:before {
  content: '\e98f';
}
.icon-plus:before {
  content: '\e990';
}
.icon-plus-circle:before {
  content: '\e991';
}
.icon-pointer:before {
  content: '\e992';
}
.icon-portal:before {
  content: '\e993';
  color: #341062;
}
.icon-pulse:before {
  content: '\e994';
}
.icon-radio:before {
  content: '\e995';
}
.icon-renovate:before {
  content: '\e996';
}
.icon-repeat:before {
  content: '\e997';
}
.icon-replace:before {
  content: '\e998';
}
.icon-review:before {
  content: '\e999';
}
.icon-rocket:before {
  content: '\e99a';
}
.icon-rocket-alt:before {
  content: '\e99b';
}
.icon-ruler-combined:before {
  content: '\e99c';
}
.icon-save:before {
  content: '\e99d';
}
.icon-schedule:before {
  content: '\e99e';
}
.icon-schedule-alt:before {
  content: '\e99f';
}
.icon-schedule-dashboard:before {
  content: '\e9a0';
}
.icon-screen-ratio:before {
  content: '\e9a1';
}
.icon-search:before {
  content: '\e9a2';
}
.icon-search-right:before {
  content: '\e9a3';
}
.icon-send:before {
  content: '\e9a4';
}
.icon-setting:before {
  content: '\e9a5';
}
.icon-share:before {
  content: '\e9a6';
}
.icon-share-screen:before {
  content: '\e9a7';
}
.icon-signout:before {
  content: '\e9a8';
}
.icon-size:before {
  content: '\e9a9';
}
.icon-smile:before {
  content: '\e9aa';
}
.icon-sortby:before {
  content: '\e9ab';
}
.icon-sortby-ascending:before {
  content: '\e9ac';
}
.icon-spacing-horizontal:before {
  content: '\e9ad';
}
.icon-spacing-vertical:before {
  content: '\e9ae';
}
.icon-stop:before {
  content: '\e9af';
}
.icon-stop-circle:before {
  content: '\e9b0';
}
.icon-suite:before {
  content: '\e9b1';
  color: #6a7074;
}
.icon-telecom:before {
  content: '\e9b2';
}
.icon-tour:before {
  content: '\e9b3';
}
.icon-trash:before {
  content: '\e9b4';
}
.icon-tv:before {
  content: '\e9b5';
}
.icon-tv-alt:before {
  content: '\e9b6';
}
.icon-tv-check:before {
  content: '\e9b7';
}
.icon-twitter:before {
  content: '\e9b8';
}
.icon-upload:before {
  content: '\e9b9';
}
.icon-user:before {
  content: '\e9ba';
}
.icon-user-circle:before {
  content: '\e9bb';
}
.icon-user-left:before {
  content: '\e9bc';
}
.icon-user-question:before {
  content: '\e9bd';
}
.icon-user-right:before {
  content: '\e9be';
}
.icon-users:before {
  content: '\e9bf';
}
.icon-user-up:before {
  content: '\e9c0';
}
.icon-video:before {
  content: '\e9c1';
}
.icon-video-slash:before {
  content: '\e9c2';
}
.icon-virtual:before {
  content: '\e9c3';
}
.icon-volume:before {
  content: '\e9c4';
}
.icon-walk:before {
  content: '\e9c5';
}
.icon-webcam:before {
  content: '\e9c6';
}
.icon-year-built:before {
  content: '\e9c7';
}
.icon-zoom-in:before {
  content: '\e9c8';
}
.icon-zoom-out:before {
  content: '\e9c9';
}
